import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { fetchCompletedAssessmentList } from "../../../../../../../actions/assessment";
import Pagination from "../../../../../../../components/Pagination";
import { assessmentStore, tablesStore } from "../../../../../../../store";
import { formatToUSDate } from "../../../../../../../helpers/date.ts";
import { ExpandButtonWrapper } from "../../general/ExpandedSection";
import { Skeleton } from "../../../../../../../components/Skeleton";

const ASSESSMENTS_PER_PAGE = 10;

const FILTER = {
  ALL: 'All',
  PRACTICE: 'Practice',
  ASSESSIVS: 'Assessivs'
};

const calculatePercentage = (revision) => {
  const correctAnswers = revision?.correctCount ?? 0;
  const inCorrectAnswers = revision?.inCorrectCount ?? 0;
  const totalAnswers = correctAnswers + inCorrectAnswers;

  if (!totalAnswers) return 0;

  return Math.floor(correctAnswers / totalAnswers * 100);
}

// @todo: temp check if the assessment is practice
const isAssessmentPractice = (assessment) => {
  return [
    'Writing - ',
    'Reading - ',
    'Math - ',
  ].some(item => assessment?.name?.startsWith(item));
}

const copyToClipboard = (string) => {
  if (string) {
    navigator.clipboard
      .writeText(string)
      .catch((error) => {
        console.error(error);
      });
  }
}

const CompletedAssessivsRows = ({ assessment }) => {
  const navigate = useNavigate();

  const getScoreByScoreName = (arrayOfScore, scoreName) =>
    arrayOfScore?.find(score => score?.name === scoreName)?.score ?? '-';

  const handleViewResults = () => {
    if (assessment.resultId) {
      navigate(`/dashboard/assessment/results?reportId=${assessment.resultId}`);
    }
  }

  return (
    <tr
      className={`px-[14px] transition-all duration-300 border-t border-alabaster group hover:bg-lightGrey text-center`}>
      <td className="break-all text-left p-2">
        <div className="group/nameCell flex items-center gap-2 truncate">
          {assessment?.name}
          <button
            className="opacity-0 group-hover/nameCell:opacity-100 transition hover:bg-gray-200 active:bg-gray-300 p-1 rounded"
            onClick={(event) => {
              event.stopPropagation();
              copyToClipboard(assessment?.name);
            }}
          >
            <ClipboardDocumentCheckIcon className="h-4"/>
          </button>
        </div>
      </td>
      <td
        className="text-center break-all border-t border-alabaster p-2">{formatToUSDate(assessment?.completedDate ?? '')}</td>
      <td className="text-center break-all border-t border-alabaster p-2">
        {getScoreByScoreName(assessment?.scores, 'Total')}
      </td>
      <td className="text-center break-all border-t border-alabaster p-2">
        {getScoreByScoreName(assessment?.scores, 'Reading and Writing')}
      </td>
      <td className="text-center break-all border-t border-alabaster p-2">
        {getScoreByScoreName(assessment?.scores, 'Math')}
      </td>
      <td className="text-center border-t border-alabaster p-2">
        <button
          className=" text-indigo-600 font-medium min-w-max transition-all opacity-0 group-hover:opacity-100"
          onClick={() => handleViewResults()}
        >
          View Report
        </button>
      </td>
    </tr>
  );
}

const CompletedAssessivs = ({assessments, thRowClassName = ''}) => {
  return (
    <table className="min-w-full">
      <thead>
      <tr className={`text-center text-fadedDenim pb-2 text-xs bg-gray-200/75 ${thRowClassName}`}>
        <th className="w-[30%] px-2 font-normal text-left py-[14px]">
          Assessiv Name
        </th>
        <th className="w-[15%] text-fadedDenim font-normal py-[14px] px-2">Date</th>
        <th className="w-[15%] text-fadedDenim font-normal py-[14px] px-2">Total Score</th>
        <th className="w-[15%] text-fadedDenim font-normal py-[14px] px-2">RW Score</th>
        <th className="w-[15%] text-fadedDenim font-normal py-[14px] px-2">Math Score</th>
        <th className="w-[10%] text-fadedDenim font-normal px-2 py-[14px]"/>
      </tr>
      </thead>

      <tbody className="text-sm text-right text-black">
      {assessments.map((assessment) => (
        <CompletedAssessivsRows
          key={`${assessment?.assessivId} ${assessment?.assessivVersionId} ${assessment?.revisionId}`}
          assessment={assessment}
        />
      ))}
      </tbody>
    </table>
  );
}

const CompletedPracticeAssessivsRow = ({assessment}) => {
  const navigate = useNavigate();
  const completedPercentage = calculatePercentage(assessment);

  const handleViewResults = () => {
    if (assessment.resultId) {
      navigate(`/dashboard/assessment/results?reportId=${assessment.resultId}`);
    }
  }

  return (
    <tr
      className={`px-[14px] transition-all duration-300 border-t border-alabaster hover:bg-lightGrey group text-center`}>
      <td className="p-2 max-w-[30%]">
        <div className="group/nameCell flex items-center gap-2">
        <span className="text-left">{assessment?.name}</span>
          <button
            className="opacity-0 group-hover/nameCell:opacity-100 transition hover:bg-gray-200 active:bg-gray-300 p-1 rounded"
            onClick={(event) => {
              event.stopPropagation();
              copyToClipboard(assessment?.name);
            }}
          >
            <ClipboardDocumentCheckIcon className="h-4"/>
          </button>
        </div>
      </td>
      <td className={`text-center break-all border-alabaster p-2`}>
        {formatToUSDate(assessment?.completedDate ?? '')}
      </td>
      <td className={`text-center break-all border-alabaster p-2`}>
        <div className="flex items-center justify-center gap-2 w-full">
          <span className="w-10">{`${completedPercentage}%`}</span>
          <div className="flex items-stretch border border-black w-16 h-4 overflow-hidden bg-persimmon">
            {!!completedPercentage && (
              <div
                style={{width: `${completedPercentage}%`}}
                className={`bg-emeraldGreen border-black ${completedPercentage !== 100 ? 'border-r' : ''}`}
              />
            )}
          </div>
        </div>
      </td>
      <td className={`text-center break-all border-alabaster p-2`}>
        {assessment?.correctCount ?? 0}
      </td>
      <td className={`text-center break-all border-alabaster`}>
        {assessment?.inCorrectCount ?? 0}
      </td>
      <td className={`text-center border-alabaster p-2 transition-all opacity-0 group-hover:opacity-100`}>
        <button
          className="text-indigo-600 font-medium min-w-max"
          onClick={() => handleViewResults()}>
          View Report
        </button>
      </td>
    </tr>
  );
}

const CompletedPracticeAssessivs = ({assessments, thRowClassName = ''}) => {
  return (
    <table className="w-full">
      <thead>
      <tr className={`text-center text-fadedDenim pb-2 text-xs bg-gray-200/75 ${thRowClassName}`}>
        <th className="w-[30%] px-2 font-normal text-left py-[14px]">
          Practice Assessiv Name
        </th>
        <th className="w-[15%] px-2 py-[14px] font-normal">
          Date
        </th>
        <th className="w-[15%] px-2 font-normal py-[14px]">
          Total
        </th>
        <th className="w-[15%] px-2 font-normal py-[14px]">
          Correct
        </th>
        <th className="w-[15%] px-2 font-normal py-[14px]">
          Incorrect
        </th>
        <th className="w-[10%] px-2 font-normal text-center py-[14px]"/>
      </tr>
      </thead>

      <tbody className="text-sm text-right text-black">
      {assessments.map((assessment) => (
        <CompletedPracticeAssessivsRow
          key={`${assessment?.assessivId} ${assessment?.assessivVersionId} ${assessment?.revisionId}`}
          assessment={assessment}
        />
      ))}
      </tbody>
    </table>
  );
}



const filterAssessmentsByType = (assessments) => {
  const practice = [];
  const regular = [];

  assessments.forEach((assessment) => {
    const resultArray = isAssessmentPractice(assessment) ? practice : regular;
    resultArray.push(assessment);
  });

  return [practice, regular];
}

const renderAssessments = (assessments, filter) => {
  if (filter === FILTER.PRACTICE) {
    return (
      <CompletedPracticeAssessivs assessments={assessments}/>
    );
  }

  if (filter === FILTER.ASSESSIVS) {
    return (
      <CompletedAssessivs assessments={assessments}/>
    );
  }

  const [practiceAssessments, regularAssessments] = filterAssessmentsByType(assessments);

  return (
    <>
      {!!practiceAssessments.length && (
        <CompletedPracticeAssessivs assessments={practiceAssessments}/>
      )}

      {!!regularAssessments.length && (
        <CompletedAssessivs assessments={regularAssessments}/>
      )}
    </>
  );
}

const FilterSection = ({ currentOption, selectOption, className = ''}) => {
  const [query, setQuery] = useState('')

  const filteredOptions = useMemo(() => {
    if (query) {
      return Object.values(FILTER).filter((option) =>
        option
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      );
    }

    return Object.values(FILTER);
  }, [query]);

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      Filter:
      <div className="w-36">
        <Combobox value={currentOption} onChange={selectOption}>
          <div className="relative mt-1">
            <div
              className="relative w-full cursor-default overflow-hidden rounded-lg bg-white border text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                displayValue={(option) => option}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                className="absolute z-10 mt-1 max-h-[240px] w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filteredOptions.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredOptions.map((option) => (
                    <Combobox.Option
                      key={option}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-blue-500 text-white' : 'text-gray-900'
                        }`
                      }
                      value={option}
                    >
                      {({selected, active}) => (
                        <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {option}
                        </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-blue-600'
                              }`}
                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
}

const revisionsToAssessments = (assessment) => {
  const { revisions = [], ...assessmentDataWithoutRevisions } = assessment;
  return revisions.map(revision => ({
    ...revision,
    ...assessmentDataWithoutRevisions,
  }))
}

export const StudentCompletedAssessments = ({className = '', assessmentPerPage = ASSESSMENTS_PER_PAGE}) => {
  const {completedAssessments} = assessmentStore();
  const {completedAssessmentTablePage, setCompletedAssessmentTablePage} = tablesStore();
  const [filter, setFilter] = useState(FILTER.ALL);

  const assessments = useMemo(() => {
    return completedAssessments
      ?.filter((assessment) => !!assessment?.revisions?.length)
      ?.map(revisionsToAssessments)
      ?.flat() ?? [];
    },
    [completedAssessments]
  );

  const sortedAssessments = useMemo(() => {
    return assessments.sort((firstAssessment, secondAssessment) =>
      new Date(secondAssessment.completedDate) - new Date(firstAssessment.completedDate)
    );
  }, [assessments]);

  const filteredAssessments = useMemo(() => {
    if (filter === FILTER.ALL) return sortedAssessments;

    return sortedAssessments.filter(assessment => isAssessmentPractice(assessment) ? filter === FILTER.PRACTICE : filter === FILTER.ASSESSIVS)
  }, [sortedAssessments, filter]);

  const paginatedAssessments = useMemo(() => {
    const firstPageIndex = assessmentPerPage * (completedAssessmentTablePage - 1);
    const lastPageIndex = assessmentPerPage * completedAssessmentTablePage - 1;
    return filteredAssessments?.filter(
      (_, index) => index >= firstPageIndex && index <= lastPageIndex) ?? [];
  }, [filteredAssessments, completedAssessmentTablePage, assessmentPerPage]);

  const handleSelectFilterOption = (filterOption) => {
    setFilter(filterOption);
    setCompletedAssessmentTablePage(1);
  }

  return (
    <div className={className}>
      <FilterSection currentOption={filter} selectOption={handleSelectFilterOption} />   
      {filteredAssessments?.length ? (
        <>               
          <div
            className="overflow-auto relative scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md w-full mb-2 mt-2">
            {renderAssessments(paginatedAssessments, filter)}
          </div>

          <div className="flex items-center justify-between mt-4">
            {/* <FilterSection className="pointer-events-auto opacity-0" /> */}
            <Pagination
              currentPage={completedAssessmentTablePage}
              onPageChange={setCompletedAssessmentTablePage}
              totalPages={Math.ceil((filteredAssessments?.length ?? 0) / 10)}
            />
          </div>
        </>
      ) : (
        <div className="text-xl text-slate-500 text-center p-4 md:p-6">
          No assessments
        </div>
      )}
    </div>
  )
}

export const AnalyticsContent = () => {
  return (
    <div className="flex flex-col p-8 md:p-6 gap-2 text-gray-500 bg-white border border-softCloud rounded-lg relative shadow-sm">
      <h2 className="text-base text-black font-medium cursor-pointer border-b-2 min-w-max border-transparent">
        Completed Assessments
      </h2>
      <StudentCompletedAssessments/>
    </div>
  );
}

export const Analytics = ({layoutId, onClickExpand}) => {
  const { completedAssessments, setCompletedAssessments } = assessmentStore();

  const assessments = useMemo(
    () => completedAssessments?.filter((assessment) => !!assessment?.revisions?.length) ?? [],
    [completedAssessments]
  );

  const [isFetching, setIsFetching] = useState(!assessments?.length);

  useEffect(() => {
    fetchCompletedAssessmentList()
      .then((data) => setCompletedAssessments(data?.data ?? []))
      .finally(() => setIsFetching(false));
  }, []);

  if (isFetching) {
    return (
      <div
        className="flex flex-col p-8 md:p-6 gap-2 text-gray-500 bg-white border border-softCloud rounded-lg relative shadow-sm">
        <h2 className="text-base text-black font-medium cursor-pointer border-b-2 min-w-max border-transparent">
          Completed Assessments
        </h2>

        <div className="flex flex-col gap-1">
          {Array.from({length: 5}, (_, index) => (
            <Skeleton key={index} className="h-8 w-full"/>
          ))}
        </div>
      </div>
    );
  }

  if (!assessments?.length) {
    return (
      <div className="rounded-md bg-gray-200/70 p-4 text-sm text-gray-600">
        <p className="text-center font-semibold">Assessments will start showing here once completed.</p>
      </div>
    );
  }

  return (
    <ExpandButtonWrapper
      layoutId={layoutId}
      onClickExpand={onClickExpand}
    >
      <AnalyticsContent />
    </ExpandButtonWrapper>
  );
}