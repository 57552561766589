import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import { groupsStore } from "../../../../../../../../../store";
import { assignUserToGroup, deleteAssignUserToGroup, fetchGroups } from "../../../../../../../../../actions/group";
import { Search } from "../../../../../../../../../components/Search";
import { SpinLoader } from "../../../../../../../../../components/SpinLoader";
import { ReactComponent as CloseCrossIcon } from "../../../../../../../../../assets/close-cross.svg";

export const ManageUsersModal = ({
  search,
  users,
  fetchedUsers,
  isLoading,
  groupId,
  addButtonClassName = '',
  handleOnChangeSearch,
  handleCloseModal,
  handleScroll,
  title,
  emptyText,
  showActions = true,
  isSearchLoading,
}) => {
  const { setGroups } = groupsStore();

  const handleAddUser = (userId) => () => {
    if (groupId && userId) {
      assignUserToGroup(groupId, userId)
        .then(() =>
          fetchGroups()
            .then((data) => {
              setGroups(data?.data ?? []);
            })
        );
    }
  }

  const handleDeleteUser = (userId, userName) => () => {
    if (groupId && userId) {
      const isConfirmed = window.confirm(`Are you sure you want to remove ${userName} from this group?`);
      
      if (isConfirmed) {
        deleteAssignUserToGroup(groupId, userId)
          .then(() =>
            fetchGroups()
              .then((data) => {
                setGroups(data?.data ?? []);
              })
          );
      }
    }
  }

  return createPortal(
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        transition={{ duration: 0.3 }}
        id="staticModal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20"
      >
        <motion.div
          variants={{
            visible: { y: 0 },
            hidden: { y: 50 },
          }}
          transition={{ duration: 0.5, stiffness: 150 }}
          className="relative w-full max-w-[570px] max-h-full"
        >
          <div className="relative bg-white rounded shadow lg:min-h-[500px]">
            <div className="flex flex-col items-stretch pt-10 pb-2 px-8 rounded-t">
              <h2 className="text-xl text-center font-medium text-navySmoke mb-2">
                {title}
              </h2>
              <button
                type="button"
                className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 ml-auto inline-flex justify-center items-center"
                data-modal-hide="staticModal"
                onClick={handleCloseModal}
              >
               <CloseCrossIcon className="w-3 h-3" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="flex items-center relative mb-4 mx-8">
              <Search
                searchString={search}
                onChangeSearchString={handleOnChangeSearch}
                className="rounded !p-2 !pr-12 w-full"
                inputClassName="flex-1"
              />
              {isSearchLoading && <SpinLoader className="absolute right-4" />}
            </div>

            <div className="pb-10">
              <div onScroll={handleScroll} className="flex flex-col items-stretch pl-8 pr-2 mr-8 bg-white max-h-[320px] overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-m">
                {users?.map((user, index) => (
                  <div
                    key={user.userId}
                    className={`flex items-center justify-between gap-1 py-2 ${!!index ? 'border-t' : ''}`}
                  >
                    {user.name}
                    <span className="text-fadedDenim text-xs mr-auto">
                      {`| ${user.email}`}
                    </span>
                    {showActions && (
                      <button
                        className="text-red-500"
                        onClick={handleDeleteUser(user.userId, user.name)}
                      >
                        <TrashIcon className="h-5"/>
                      </button>
                    )}
                  </div>
                ))}
                {fetchedUsers?.map((student, index) => (
                  <div
                    key={student.userId}
                    className={`flex items-center justify-between gap-1 py-2 ${(!!index || !!users?.length)? 'border-t' : ''}`}
                  >
                    {student.name}
                    <span className="text-fadedDenim text-xs mr-auto">
                      {`| ${student.email}`}
                    </span>
                    {showActions && (
                      <button
                        className={`flex items-center justify-center h-[30px] ${addButtonClassName}`}
                        onClick={handleAddUser(student.userId)}
                      >
                        <PlusCircleIcon className="h-5"/>
                      </button>
                    )}
                  </div>
                ))}
                {!users?.length && !fetchedUsers?.length && (
                  <span
                    className="inline-block w-full mt-4 text-center text-lg text-fadedDenim">{emptyText ?? 'No users'}</span>
                )}
                {isLoading && (
                  <span className="inline-block w-full mt-4 text-center text-lg text-fadedDenim">Loading...</span>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>,
    document.body
  );
}