import { assessmentStore } from "../../../../../../../store";
import { StatusLabel } from "../../general/StatusLabel";
import { AssignedStudents } from "./AssignedStudents";
import { EDUCATOR_INTROJS_SELECTOR } from "../EducatorDashboardExcursion";
import { ASSESSMENT_STATUS } from "../../../../../../../helpers/constants";
import { Skeleton } from "../../../../../../../components/Skeleton";

const ASSESSMENTS_IN_UNEXPANDED_MODE = 10;

export const RecentlyAssigned = () => {
  const { isFetchingStudentResults, studentsResults } = assessmentStore();

  return (
    <div className={`flex flex-col p-6 bg-white border border-softCloud rounded-lg shadow-sm ${EDUCATOR_INTROJS_SELECTOR.RECENTLY_ASSIGNED}`}>
      <h2 className="text-base font-medium mb-3">Recently Assigned</h2>

      {isFetchingStudentResults ? (
        <div className="flex flex-col max-h-[256px] -mr-5 pr-5 overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md scrollbar-rounded-md">
          {Array.from({ length: ASSESSMENTS_IN_UNEXPANDED_MODE }, (_, index) => (
            <div key={index} className={`w-full py-3 border-t ${index === 0 ? 'border-transparent' : 'border-gray-300'}`}>
              <Skeleton className={`min-h-[24px] w-full `} />
            </div>
          ))}
        </div>
      ) : (
        <>
          {!!studentsResults?.length ? (
            <div
              className="flex flex-col max-h-[256px] -mr-5 pr-5 overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md scrollbar-rounded-md">
              {[...(studentsResults ?? [])]
                .filter(item => !item?.isComplete)
                .slice(0, ASSESSMENTS_IN_UNEXPANDED_MODE)
                .map((result, index) => (
                  <div
                    key={`${result.assessivId} ${result.studentEmail} ${index}`}
                    className={`py-3 flex flex-col gap-2 border-t cursor-pointer ${index === 0 ? 'border-transparent' : 'border-gray-300'}`}
                  >
                    <div className="flex gap-4 items-center justify-between">
                      <span className="text-deepSpaceBlue text-xs break-all">{result?.name}</span>
                      <span className="text-fadedDenim text-xs text-right">
                    <StatusLabel
                      status={result?.isComplete ? ASSESSMENT_STATUS.COMPLETED : ASSESSMENT_STATUS.IN_PROGRESS}/>
                  </span>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="text-xl text-slate-500 text-center p-4 md:p-6 pt-8 md:pt-12">
              No assessments
            </div>
          )}
        </>
      )}
    </div>
  );
}

export const RecentlyAssignedExpanded = () => {
  const { studentsResults } = assessmentStore();

  return (
    <div className="flex flex-col p-8 md:p-6 text-gray-500 bg-white border border-softCloud rounded-lg shadow-sm">
      <h2 className="text-base text-navySmoke font-medium mb-3">Recently Assigned</h2>

      {studentsResults?.length ? (
        <AssignedStudents />
      ) : (
        <div className="text-xl text-slate-500 text-center p-4 md:p-6 pt-8 md:pt-16">
          No assessments
        </div>
      )}
    </div>
  );
}