import { useEffect, useMemo, useRef, useState } from "react";
import { ReportQuestion } from "./ReportQuestion";
import { SafeHTML } from "../../../helpers/safeHtml";
import { assessmentStore, questionStore } from "../../../store";
import {
  fetchUserAnswer,
  getQuestionCrossOutOptions,
  saveCrossOut,
  postUserAnswer,
  deleteCrossOut
} from "../../../actions/assessment";
import { OTPInput } from "../../../components/OTPInput";
import { RichInput } from "../../../components/RichInput";
import { HighlightableSafeHTML } from "../../../components/highlightableSafeHTML";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside.ts";

// Letter array
const letterMap = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const ChoiceItem = ({ selected, onClick, index, children, id, handleOnClickCrossOut, crossOutOptions }) => {
  const { isStrikeMode } = questionStore();

  const [isStrike, setIsStrike] = useState(crossOutOptions.find(option => option.optionId === id));

  const onClickStrike = () => {
    const newValue = !isStrike;
    handleOnClickCrossOut(id, newValue);
    setIsStrike(() => newValue);
  }

  return (
    <div className="flex justify-between w-full">
      <div
        className={`flex flex-row group max-w-md w-full gap-3 border border-gray-300 ${isStrikeMode && isStrike && !selected ? 'line-through text-gray-400 bg-gray-200 hover:bg-gray-200 active:bg-gray-200' : 'active:bg-blue-200 hover:border-blue-300 hover:bg-blue-100'} p-2 rounded-md cursor-pointer ${selected ? 'bg-blue-200 border-blue-300' : ''}`}
        onClick={onClick}
      >
        <div className={`flex justify-center items-center h-6 w-6 border border-gray-400 ${isStrikeMode && isStrike && !selected ? 'bg-gray-100 !text-gray-400 group-hover:text-gray-400' : 'group-hover:bg-blue-600 group-hover:border-blue-600'} ${selected ? 'border-blue-600 bg-blue-600 text-white' : 'text-gray-900 group-hover:text-white'} rounded bg-gray-100`}>
          {index}
        </div>

        <span className={`flex-1 font-medium ${isStrikeMode && isStrike ? 'text-gray-400' : 'text-gray-800 group-hover:text-blue-600'} ${selected ? 'text-blue-600' : ''}`}>
          {children}
        </span>
      </div>

      {isStrikeMode && (
        <div className="ml-auto p-2 cursor-pointer text-sm flex-shrink-0" onClick={onClickStrike}>
          {isStrike ? (
            <div className="underline text-black">
              Undo
            </div>
          ) : (
            <div className="flex items-center justify-center flex-shrink-0 relative">
              <div className="flex justify-center items-center flex-shrink-0 group-hover:bg-blue-600 group-hover:text-white h-6 w-6 border border-gray-400 text-gray-900 group-hover:border-blue-600 rounded bg-gray-100 line-through">
                {index}
              </div>
              <div className="absolute -left-1 w-8 border-b-2 border-black z-10"/>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const PassageDetail = ({ passage, citation }) => {
  return (
    <div className="text-gray-600 mt-6 relative bg-slate-100 rounded-lg mb-4">
      <div className="p-5 prev-html">
        <HighlightableSafeHTML
          className="prose"
          content={passage.replaceAll('&nbsp;', ' ')}
        />
      </div>
      {!!citation && (
        <div className="ml-5 mt-2 text-gray-400 italic">{citation}</div>
      )}
    </div>
  );
};

export default function Questions() {
  const {
    sectionName,
    sectionOrder,
    questionOrder,
    sectionId,
    questionSelected,
    optionSelected,
    isStrikeMode,
    setStrikeMode,
    setOptionSelected
  } = questionStore();

  const {
    subSectionData,
    assessmentId,
    assessmentVersionId,
    resultsReady,
    educatorId,
    addAnsweredQuestion,
  } = assessmentStore();

  const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
  const [toggleClickOutside, setToggleClickOutside] = useState(false);
  const [crossOutOptions, setCrossOutOptions] = useState([]);
  const containerRef = useRef(null);

  const question = useMemo(
    () => subSectionData?.find(question => question?.questionId === questionSelected),
    [subSectionData, questionSelected]
  );

  const handleSelectAnswer = (optionId) => {
    return postUserAnswer({
      questionId: question?.questionId,
      isFlagged: question?.isFlagged,
      optionId,
      sectionId: sectionId,
      assessivId: assessmentId,
      assessivVersionId: assessmentVersionId,
      educatorId
    }).then(() => {
      fetchUserAnswer({
        questionId: question?.questionId,
        isFlagged: question?.isFlagged,
        sectionId: sectionId,
        assessivId: assessmentId,
        assessivVersionId: assessmentVersionId,
        optionId: 'optionId',
        educatorId
      }).then(data => {
        setOptionSelected(data?.data);
        addAnsweredQuestion(question?.questionId);
      })
    });
  }

  const loadCrossOutOptions = (questionId) => {
    getQuestionCrossOutOptions({
      assessivId: assessmentId,
      assessivVersionId: assessmentVersionId,
      educatorId,
      questionId,
    })
      .then((data) => {
        if (Array.isArray(data?.data)) {
          setCrossOutOptions(data.data);
        }
      })
      .catch((error) => {
        setCrossOutOptions([]);
        console.log(error);
      });
  }

  useEffect(() => {
    if (question?.questionId) {
      setIsLoadingQuestion(true);
      fetchUserAnswer({
        questionId: question?.questionId,
        sectionId: sectionId,
        assessivId: assessmentId,
        assessivVersionId: assessmentVersionId,
        isFlagged: question?.isFlagged,
        optionId: 'optionId',
        educatorId
      }).then(data => {
        setOptionSelected(data?.data);
      })
        .finally(() => setIsLoadingQuestion(false));
      loadCrossOutOptions(question?.questionId);
    }
  }, [question]);

  const handleOnClickCrossOut = (optionId, isNewValueStrike) => {
    if (optionId) {
      const handler = isNewValueStrike ? saveCrossOut : deleteCrossOut;
      handler({
        assessivId: assessmentId,
        assessivVersionId: assessmentVersionId,
        educatorId,
        questionId: question?.questionId,
        optionId,
      })
        .then(() => {
          loadCrossOutOptions(question?.questionId);
        })
        .catch(console.log);
    }
  }

  useClickOutside(
    containerRef,
    () => setToggleClickOutside(prev => !prev)
  );

  if (resultsReady) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <h2 className="text-xl text-black">You completed the assessiv!</h2>
      </div>
    )
  }

  if (!question || isLoadingQuestion) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <h2 className="text-xl text-slate-400">Loading question...</h2>
      </div>
    );
  }

  return (
    <div ref={containerRef} className="prev-html question-prev relative h-full pr-6 -mr-6 overflow-y-auto scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
      <div className="flex flex-row items-center gap-2 pb-4 bg-white sticky top-0 z-10">
        <div className="flex justify-center items-center h-6 w-6 border border-gray-300 rounded text-gray-500">{sectionOrder}</div>
        <span className="text-gray-500">
          {sectionName}&nbsp;
          /&nbsp;
          <span className="font-semibold">Question {questionOrder}</span>
        </span>

        <button
          className={`flex items-center justify-center relative p-1 rounded border-2 border-black text-sm ml-auto ${isStrikeMode ? 'text-white bg-skyBlue' : 'text-black'}`}
          onClick={() => setStrikeMode(!isStrikeMode)}
        >
          ABC
          <div className={`absolute z-10 -rotate-45 w-full border-b-2 ${isStrikeMode ? 'border-white' : ' border-black'}`} />
        </button>

        <ReportQuestion />
      </div>

      {!!question.passage?.hasPassage && !!question.passage?.passageText?.length && (
        <PassageDetail
          passage={question.passage.passageText}
          citation={null}
        />
      )}

      <div className="text-gray-700">
        <HighlightableSafeHTML
          className="prose"
          content={question?.prompt}
        />
      </div>

      <div className="flex flex-col gap-3 mt-4">
        {question.type === 'grid-in' ? (
          <div className="flex flex-col gap-2 mt-2">
            <h2 className="text-black font-semibold">Answer:</h2>
            <OTPInput value={optionSelected} getValue={handleSelectAnswer} slotClassName="text-black" debounceTime={1000} />
          </div>
        ) : (
          question?.questionOptionResponses?.map((option, index) => (
            <ChoiceItem
              key={option.optionId}
              index={letterMap[index]}
              selected={optionSelected === option.optionId}
              onClick={() => handleSelectAnswer(option.optionId)}
              id={option.optionId}
              crossOutOptions={crossOutOptions}
              handleOnClickCrossOut={handleOnClickCrossOut}
            >
              <SafeHTML html={option.description.replaceAll('&nbsp;', ' ')} />
            </ChoiceItem>
          ))
        )}
      </div>
    </div>
  );
}
